import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`*`}{`There will only be one 6:00am class today at The Ville.  All other
classes at East and the Ville are on as scheduled.`}</strong></p>
    <p>{`Clean Grip Deadlifts 8-8-8-8\\@70% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15-12-9 reps each of:`}</p>
    <p>{`Deadlifts 255/175`}</p>
    <p>{`HSPU’s`}</p>
    <p>{`*`}{`*`}{`Bonus WOD`}{`*`}{`*`}</p>
    <p>{`500m Row`}</p>
    <p>{`50 Double Unders`}</p>
    <p>{`250m Row`}</p>
    <p>{`25 Double Unders`}</p>
    <p>{`for time.`}</p>
    <p><strong parentName="p">{`*`}{`New Ville shirts and tanks have arrived! Check them out next time
you’re in.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Our 2nd Annual Pre Open in house competition will start January 1st
so get ready! RX Men, RX Women, scaled, and masters (50+) divisions.
More details to come.`}</strong></p>
    <p><strong parentName="p">{`*`}{`The next Strength Program will start January 7th. For more
information email Eric at: Fallscitystrength\\@gmail.com.`}</strong></p>
    <p><strong parentName="p">{`*`}{`Saturday, February 4th, we will have our 3rd Annual Sports Nutrition
seminar will Dawn from Sports Nutrition 2 Go in Cincinnati. Metabolic
Testing will be offered before and after the seminar as well!`}</strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      